import * as OfflinePluginRuntime from 'offline-plugin/runtime';
//
// import './fonts/galano-grotesque-alt-medium.woff';
// import './fonts/galano-grotesque-alt-medium.woff2';

import './index.html';
import './index.scss';
import './scripts/script';

OfflinePluginRuntime.install();
